.LoginError {
  text-align: left;
}

.LoginError-header {
  background-color: #282c34;
  min-height: 100vh;
  color: white;
}

.LoginError-p {
  margin-block-start: 0;
  padding-top: 1em;
  padding-left: 1em;
}
