@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue'), local('HelveticaNeue'),
    url('../../fonts/hinted-HelveticaNeue.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue Light Italic'), local('HelveticaNeue-LightItalic'),
    url('../../fonts/hinted-HelveticaNeue-LightItalic.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue Condensed Bold'), local('HelveticaNeue-CondensedBold'),
    url('../../fonts/hinted-HelveticaNeue-CondensedBold.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-CondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue Bold'), local('HelveticaNeue-Bold'),
    url('../../fonts/hinted-HelveticaNeue-Bold.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue Medium'), local('HelveticaNeue-Medium'),
    url('../../fonts/hinted-HelveticaNeue-Medium.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue Bold Italic'), local('HelveticaNeue-BoldItalic'),
    url('../../fonts/hinted-HelveticaNeue-BoldItalic.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue Light'), local('HelveticaNeue-Light'),
    url('../../fonts/hinted-HelveticaNeue-Light.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue Medium Italic'), local('HelveticaNeue-MediumItalic'),
    url('../../fonts/hinted-HelveticaNeue-MediumItalic.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue Italic'), local('HelveticaNeue-Italic'),
    url('../../fonts/hinted-HelveticaNeue-Italic.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue Condensed Black'), local('HelveticaNeue-CondensedBlack'),
    url('../../fonts/hinted-HelveticaNeue-CondensedBlack.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-CondensedBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue UltraLight Italic'), local('HelveticaNeue-UltraLightItalic'),
    url('../../fonts/hinted-HelveticaNeue-UltraLightItalic.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-UltraLightItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue Thin'), local('HelveticaNeue-Thin'),
    url('../../fonts/hinted-HelveticaNeue-Thin.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue UltraLight'), local('HelveticaNeue-UltraLight'),
    url('../../fonts/hinted-HelveticaNeue-UltraLight.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-UltraLight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue Thin Italic'), local('HelveticaNeue-ThinItalic'),
    url('../../fonts/hinted-HelveticaNeue-ThinItalic.woff2') format('woff2'),
    url('../../fonts/hinted-HelveticaNeue-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nike TG';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/Nike-TG.woff2') format('woff2'),
    url('../../fonts/Nike-TG.woff') format('woff');
}

@font-face {
  font-family: 'Nike Futura';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/Nike-Futura.woff2') format('woff2'),
    url('../../fonts/Nike-Futura.woff') format('woff');
}

body {
  font: var(--eds-type-body-2) !important;
}
